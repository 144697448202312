<template>
  <div class="content">
    <h1>统一指挥中心</h1>
    <p>
      指挥中心依托网格化管理，构建全域覆盖的社会治理“一张网”，真正实现“全域联动、一网统管、一体指挥”，不断提升基层社会治理现代化水平
    </p>
    <el-carousel :interval="4000" type="card" height="500px">
      <el-carousel-item v-for="item in list" :key="item.id">
        <img :src="item.url" alt="" style="width: 100%; height: 100%" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {
      list: [
        { id: 0, url: require("@/assets/image/theBasicLevel/318.png") },
        {
          id: 1,
          url: require("../../../../assets/image/theBasicLevel/319.png"),
        },
        {
          id: 2,
          url: require("../../../../assets/image/theBasicLevel/320.png"),
        },
      ],
    };
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.content {
  padding: 0 10%;
  background: url("../../../../assets/image/theBasicLevel/蒙版组 90.png");
  min-height: 650px;
  margin-bottom: 9px;
  > h1 {
    font-family: PingFang SC;
    font-weight: 600;
    color: #000000;
    opacity: 1;
    margin: 30px 0 30px 0;
  }
  > p {
    height: 25px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 300;
    color: #333333;
    margin-bottom: 40px;
  }
}
</style>